import AUTH from './auth.service';
import USER from './user.service';
import SETTINGS from './settings.service';
import TICKETS from './tickets.service';
import FAQS from './faqs.service';
import NEWS from './news.service';
import BANNER from './banner.service';
import COMPLAINTS from './complaints.service';
import HOLDERS from './holders.service';
import USERTRANSACTIONS from './userTransactions.service';
import PARTNERTRANSACTIONS from './partnerTransaction.service';
import PARTNER from './partner.service';
import ROUTESPUBLIC from './routesPublic.service';
import CONTACTS from './contacts.service';
import BUS_STOPS from './busStops.service';
import HOLDERSNUBERBALANCE from './holdersActions.service';
import PARTNERTRANSPORTS from './partnersTransports.service';
import DRIVERS from './driver.service';
import PARTNERROUTES from './partnerRoutes.service';
import PARTNERTRANSFER from './partnerTransfers.service';
import FORGOTPASSWORD from './forgotPassword.service';
import AUTHOTP from './authOtp.service';
import RESETPASSWORD from './resetPassword.service';

const SERVICES = {
	USERTRANSACTIONS,
	PARTNERTRANSACTIONS,
	AUTH,
	USER,
	SETTINGS,
	TICKETS,
	FAQS,
	NEWS,
	BANNER,
	COMPLAINTS,
	HOLDERS,
	PARTNER,
	ROUTESPUBLIC,
	CONTACTS,
	BUS_STOPS,
	HOLDERSNUBERBALANCE,
	PARTNERTRANSPORTS,
	DRIVERS,
	PARTNERROUTES,
	PARTNERTRANSFER,
	FORGOTPASSWORD,
	AUTHOTP,
	RESETPASSWORD
};

export default SERVICES;

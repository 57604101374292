import {useDispatch, useSelector} from 'react-redux';
import DataExport from '../../../../Components/DataExport/DataExport';
import {IPassengerTickets} from '../../../../utils/types';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import './MyTickets.less';
import OwnedTicket from './OwnedTicket/OwnedTicket';
import filter_fields from './filter.form';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import {useEffect, useState} from 'react';
import {getMyTicketsThunk} from '../../../../store/account.userTickets.slice';
import {Empty} from 'antd';
import i18n from '../../../../utils/translate/i18';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {useLocation} from 'react-router-dom';

const MyTickets = () => {
	let dispatch = useDispatch();
	const currentPageName = '/account/tickets';
	let {t} = useTranslation();
	const {pathname} = useLocation();

	const myTickets = useSelector(({userTickets}) => userTickets);
	const [pageSize, setPageSize] = useState(4);
	const [info, setInfo] = useState<any>(null);

	const {currentItemsData, currentPage, goToPage} = usePagination(
		myTickets?.myTickets?.items,
		myTickets?.myTickets?.pageSize
	);
	useEffect(() => {
		if (currentPageName === pathname) {
			dispatch(
				getMyTicketsThunk({
					filter: info ? {...info, status: undefined} : undefined,
					page: currentPage,
					take: pageSize
				})
			);
		}
	}, [info, currentPage, pageSize, pathname]);
	const columns = [
		{dataIndex: 'holderType', title: t('pages.tickets.oneUse.ticketCard.storage')},
		{dataIndex: 'ticketType', title: t('pages.tickets.oneUse.ticketCard.ticketType')},
		{dataIndex: 'name', title: t('pages.account.myTickets.filter.filterBy.ticketName')},
		{dataIndex: 'price', title: t('pages.tickets.oneUse.ticketCard.price')},
		{dataIndex: 'tripsCount', title: t('pages.tickets.oneUse.ticketCard.tripCount')},
		{dataIndex: 'description', title: t('pages.account.myTickets.description')},
		{dataIndex: 'creationDate', title: t('pages.account.myTickets.filter.filterBy.purchaseDate')},
		{dataIndex: 'expirationDate', title: t('pages.account.myTickets.filter.filterBy.validDate')},
		{dataIndex: 'valid', title: t('pages.account.myTickets.filter.filterBy.valid.title')}
	];
	const rows = currentItemsData?.map((ticket: any) => {
		return {
			holderType: ticket.holderType[i18n.language],
			ticketType: ticket.type[i18n.language],

			name: ticket[
				`name${i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1).toLowerCase()}`
			],
			tripsCount: ticket.tripsCount,
			price: ticket.price,
			description:
				ticket[
					`tariffDescription${
						i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1).toLowerCase()
					}`
				],
			creationDate: dayjs(ticket.createdAt).format('DD-MM-YYYY').toString(),
			expirationDate: dayjs(ticket.expirationDate).format('DD-MM-YYYY').toString(),
			valid: ticket.isValid
				? t('pages.account.myTickets.filter.filterBy.valid.options.valid')
				: t('pages.account.myTickets.filter.filterBy.valid.options.invalid')
		};
	});
	return (
		<div className="my-tickets">
			<div className="my-tickets__container">
				<div className="my-tickets__content">
					<div className="my-tickets__row">
						<div className="my-tickets__top">
							<DataExport exportData={{rows, columns}} />
							<DefaultWrapper
								filteritems={filter_fields}
								defaultFilters={info}
								applyFilters={(data: any) => {
									let isUsed = undefined;
									let isExpired = undefined;

									if (data) {
										const {status, ...restData} = data;
										if (status === 'used') {
											isUsed = true;
										} else if (status === 'expired') {
											isUsed = false;
											isExpired = true;
										} else if (status === 'valid') {
											isUsed = false;
											isExpired = false;
										}
										const payload: any = {
											isUsed,
											...restData
										};
										if (!isUsed) {
											payload.isExpired = isExpired;
										}
										setInfo({...payload, status});
									} else {
										setInfo(data);
									}
								}}
							/>
						</div>

						<div className="my-tickets__owned">
							{currentItemsData && currentItemsData.length > 0 ? (
								currentItemsData?.map((el: IPassengerTickets) => (
									<OwnedTicket ticket={el} key={el.id} />
								))
							) : (
								<Empty />
							)}
						</div>
					</div>
				</div>
				{currentItemsData && currentItemsData.length > 0 && (
					<PagePagination
						pageSizeOptions={[4, 10, 20, 50, 100]}
						pageSize={myTickets.myTickets.pageSize}
						currentPage={myTickets.myTickets.currentPage}
						totalItems={myTickets.myTickets.totalCount}
						onPageChange={goToPage}
						onTakeChange={size => {
							setPageSize(size);
							goToPage(1); // Reset to the first page when page size changes
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default MyTickets;

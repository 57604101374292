import axiosClient from '../utils/axiosClient';
import API from '../api';

const createComplaint = async (data: any) => {
	try {
		const res = await axiosClient.post(API.COMPLAINTS.complaints, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return res.data;
	} catch (error) {
		throw error;
	}
};

const getUserComplaints = async (data: {filter: any; page: number; take:number}) => {
	try {
		const res = await axiosClient.get(`${API.COMPLAINTS.complaints}`, {
			params: {
				...data.filter,
				page: data.page,
				take:data.take
			}
		});
		return res.data;
	} catch (error) {
		throw error;
	}
};

const COMPLAINT = {
	createComplaint,
	getUserComplaints
};

export default COMPLAINT;

import {NamePath} from 'antd/es/form/interface';
import dayjs from 'dayjs';
import {setAuthModalVisible} from '../../../store/auth.slice';
import {
	armenianPhoneNumberRule,
	internationalPhoneNumberRule,
	latinRulesWithNumber,
	maxChar,
	minChars,
	passwordRules
} from '../../../utils/rules/validation';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';

interface FiledsTypes {
	(t: TranslationFuncType, dispatch: (data: any) => void, isLoading?: boolean): ModalFormFieldTypes[];
}

const fields: FiledsTypes = (t, dispatch) => [
	{
		name: 'info',
		type: 'group',
		colProps: {
			xs: 24
		},
		inputs: [
			{
				name: 'firstName',
				label: t('fields.name'),
				type: 'text',
				colProps: {
					xs: 24,
					lg: 12
				},
				rules: [minChars(t, 2), maxChar(t, 50)]
			},
			{
				name: 'lastName',
				label: t('fields.surname'),
				type: 'text',
				colProps: {
					xs: 24,
					lg: 12
				},
				rules: [minChars(t, 2), maxChar(t, 50)]
			},
			{
				name: 'email',
				autoComplete: 'new-email',
				label: t('fields.email'),
				type: 'text',
				rules: [{type: 'email'}, latinRulesWithNumber(t)],
				colProps: {
					xs: 24,
					lg: 12
				}
			},
			{
				name: 'phoneNumber',
				label: t('fields.phone'),
				type: 'phone',

				inputProps: {addonBefore: '+'},
				rules: [internationalPhoneNumberRule(t)],
				colProps: {
					xs: 24,
					lg: 12
				}
			},
			{
				style: {
					height: 51
				},
				name: 'genderId',
				label: t('fields.gender'),
				type: 'select',
				colProps: {
					xs: 24,
					lg: 12
				},
				selectProps: {
					options: [
						{
							label: t('signUp.gender.male'),
							value: '1'
						},
						{
							label: t('signUp.gender.female'),
							value: '2'
						}
					]
				}
			},

			{
				name: 'dob',
				label: t('fields.dob'),
				type: 'date',
				colProps: {
					xs: 24,
					lg: 12
				},
				datePickerProps: {
					format: 'DD-MM-YYYY',
					defaultPickerValue: dayjs(dayjs().subtract(7, 'years')),
					allowClear: true,
					disabledDate: (current: any) => {
						let customDate = dayjs().subtract(0, 'day').subtract(7, 'year');
						return current && current.isAfter(customDate);
					}
				}
			}
		]
	},
	{
		name: 'passinfo',
		type: 'group',
		colProps: {
			xs: 24
		},
		inputs: [
			{
				name: 'password',
				label: t('fields.createPassword'),
				type: 'password',
				autoComplete: 'new-password',
				className: 'passwordInput',
				rules: [passwordRules(t)],
				colProps: {
					xs: 24,
					lg: 12
				}
			},
			{
				name: 'rePassword',
				label: t('fields.rePassword'),
				type: 'password',
				className: 'passwordInput',
				autoComplete: 'new-password',

				rules: [
					({getFieldValue}: {getFieldValue: (name: NamePath) => any}) => ({
						validator(_: any, value: any) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(new Error(t('messages.error.passwordNotMatch')));
						}
					})
				],
				colProps: {
					xs: 24,
					lg: 12
				}
			}
		]
	},
	{
		type: 'customElement',
		component: () => {
			return (
				<div className="auth-component">
					{t('header.alreadyRegistered')}{' '}
					<span
						onClick={() => {
							dispatch(setAuthModalVisible({visible: 'SIGN_IN'}));
						}}>
						{t('header.sign_in')}
					</span>
				</div>
			);
		},
		colProps: {
			xs: 24
		}
	}
];

export default fields;

import forgotPass_fields from '../../Components/AuthModalForm/forms/ForgotPassword';
import signIn_fields from '../../Components/AuthModalForm/forms/SignIn.form';
import signUp_fields from '../../Components/AuthModalForm/forms/SignUp.form';
import otpVerification_fields from '../../Components/AuthModalForm/forms/OtpVerification';
import resetPassword_fields from '../../Components/AuthModalForm/forms/resetPassword';
import otpConfirmation_fields from '../../Components/AuthModalForm/forms/OtpConfirmation';
import signInWithEmail_fields from '../../Components/AuthModalForm/forms/SignInWithEmail.form';

import i18n from '../translate/i18';
import {TranslationFuncType} from '../types';
import dayjs from 'dayjs';

const setUser = () => {
	let user = JSON.parse(localStorage.getItem('user') || '{}');
	const token = localStorage.getItem('token');
	if (user?.name && user?.email && user.roleId && token) {
		user = {...user, isAuthenticated: true};
	} else {
		user = {};
	}
	return user;
};

export const clearLocalStorage = () => {
	const lang = localStorage.getItem('language');
	localStorage.clear();
	localStorage.setItem('language', lang || 'hy');
};
const mutatedateRangeKey = (state: Record<string, any> | string, keys: string[] = ['createdAt']) => {
	let newData: Record<string, any> = {};
	const isStateString = typeof state === 'string';

	if (isStateString) {
		newData[keys[0]] = state; // If state is a string, assign it to the first key
	} else {
		newData = {...state};
	}

	keys.forEach(key => {
		if (Array.isArray(newData[key]) && newData[key]?.length) {
			newData[key] = `${dayjs(dayjs(newData[key][0]).format('YYYY-MM-DD'))
				.startOf('day')
				.toISOString()}_${dayjs(dayjs(newData[key][1]).format('YYYY-MM-DD'))
				.endOf('day')
				.toISOString()}`;
		}
	});

	return isStateString ? newData[keys[0]] : newData;
};

const helperFuncs = {setUser, clearLocalStorage, mutatedateRangeKey};

export default helperFuncs;

/* AUTH_NODAL */
export function getAuthFields({
	visible,
	t,
	dispatch,
	isSuccess,
	isLoading,
	otpEmail
}: {
	visible:
		| false
		| 'SIGN_IN'
		| 'SIGN_UP'
		| 'FOGOT_PASSWORD'
		// USED 'OTP_VERIFICATION' WHEN RESETTING PASSWORD AND OTHER CASES EXCEPT SIGNING IN
		| 'OTP_VERIFICATION'
		// USED'OTP_CONFIRMATION' WHEN SIGNING IN
		| 'OTP_CONFIRMATION'
		| 'RESET_PASSWORD'
		| 'SIGN_IN_WITH_EMAIL'
		| 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'
		| 'SET_PASSWORD';
	t: TranslationFuncType;
	dispatch: (data: any) => void;
	isSuccess?: boolean;
	isLoading?: boolean;
	otpEmail?: string | null;
}) {
	switch (visible) {
		case 'SIGN_IN':
			return signIn_fields(t, dispatch, isLoading);
		case 'SIGN_IN_WITH_EMAIL':
			return signInWithEmail_fields(t, dispatch, isLoading);
		case 'SIGN_UP':
			return signUp_fields(t, dispatch, isLoading);
		case 'FOGOT_PASSWORD':
			return forgotPass_fields(t, dispatch, isSuccess);
		case 'OTP_VERIFICATION':
			return otpVerification_fields(t, dispatch, isSuccess, otpEmail);
		case 'OTP_CONFIRMATION':
			return otpConfirmation_fields(t, dispatch, isSuccess, otpEmail);
		case 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL':
			return otpConfirmation_fields(t, dispatch, isSuccess, otpEmail);
		case 'RESET_PASSWORD':
			return resetPassword_fields(t, dispatch, isSuccess);
		case 'SET_PASSWORD':
			return resetPassword_fields(t, dispatch, isSuccess);
		default:
			return signIn_fields(t, dispatch);
	}
}

export function getTitle(
	val:
		| false
		| 'SIGN_IN'
		| 'SIGN_UP'
		| 'FOGOT_PASSWORD'
		| 'OTP_VERIFICATION'
		| 'OTP_CONFIRMATION'
		| 'RESET_PASSWORD'
		| 'SIGN_IN_WITH_EMAIL'
		| 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'
		| 'SET_PASSWORD',
	t: TranslationFuncType
) {
	switch (val) {
		case 'SIGN_IN':
			return t('header.sign_in');
		case 'SIGN_IN_WITH_EMAIL':
			return t('header.sign_in');
		case 'SIGN_UP':
			return t('header.sign_up');
		case 'FOGOT_PASSWORD':
			return t('header.passwordRecovery');
		case 'OTP_VERIFICATION':
			return t('header.emailVerification');
		case 'OTP_CONFIRMATION':
			return t('header.emailVerification');
		case 'RESET_PASSWORD':
			return t('header.resetPassword');
		case 'SET_PASSWORD':
			return t('header.setPassword');
		default:
			return t('header.sign_in');
	}
}

export function getOkText(
	val:
		| false
		| 'SIGN_IN'
		| 'SIGN_UP'
		| 'FOGOT_PASSWORD'
		| 'OTP_VERIFICATION'
		| 'OTP_CONFIRMATION'
		| 'RESET_PASSWORD'
		| 'SIGN_IN_WITH_EMAIL'
		| 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'
		| 'SET_PASSWORD',
	t: TranslationFuncType
) {
	switch (val) {
		case 'SIGN_IN':
			return t('actions.logIn');
		case 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL':
			return t('actions.logIn');
		case 'SIGN_IN_WITH_EMAIL':
			return t('actions.logIn');
		case 'SIGN_UP':
			return t('actions.register');
		case 'FOGOT_PASSWORD':
			return t('actions.confirm');
		case 'OTP_VERIFICATION':
			return t('actions.confirm');
		case 'OTP_CONFIRMATION':
			return t('actions.confirm');
		case 'RESET_PASSWORD':
			return t('actions.confirm');
		default:
			return t('actions.save');
	}
}

export function getDialogSize(
	val:
		| false
		| 'SIGN_IN'
		| 'SIGN_UP'
		| 'FOGOT_PASSWORD'
		| 'OTP_VERIFICATION'
		| 'OTP_CONFIRMATION'
		| 'RESET_PASSWORD'
		| 'SIGN_IN_WITH_EMAIL'
		| 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'
		| 'SET_PASSWORD'
) {
	switch (val) {
		case 'SIGN_IN':
			return 600;
		case 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL':
			return 600;
		case 'SIGN_IN_WITH_EMAIL':
			return 600;
		case 'SIGN_UP':
			return 1000;
		case 'FOGOT_PASSWORD':
			return 600;
		case 'OTP_VERIFICATION':
			return 600;
		case 'OTP_CONFIRMATION':
			return 600;
		case 'RESET_PASSWORD':
			return 600;
		case 'SET_PASSWORD':
			return 600;
		default:
			return 800;
	}
}
export function getKeyByLang(key: string) {
	return key + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1);
}

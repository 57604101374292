import HistoryMarches from './components/HistoryMarches/HistoryMarches';
import MyTickets from './components/MyTickets/MyTickets';
import Notifications from './components/Notifications/Notifications';
import PaymentHistory from './components/PaymentHistory/PaymentHistory';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import TicketHolders from './components/TicketHolders/TicketHolders';
import group from '../../assets/icons/Group 39895.svg';
import notification from '../../assets/icons/Notification icon.svg';
import card from '../../assets/icons/card.svg';
import ticket from '../../assets/icons/Tickets icon.png';
import user from '../../assets/icons/user.svg';
import wailt from '../../assets/icons/wailt.svg';
import way from '../../assets/icons/wya.svg';
import myBalance from './../../assets/icons/myBalanceIcon.svg';
import drivers from './../../assets/icons/drivers.svg';
import buses from './../../assets/icons/buses.svg';
import {TranslationFuncType} from '../../utils/types';
import PartnerDetails from './components/Partner/PartnerDetails/PartnerDetails';
import Routes from './components/Partner/Routes/Routes';
import TransfersHistory from './components/Partner/TransfersHistory/TransfersHistory';
// import ProvidedServices from './components/Partner/ProvidedServices/ProvidedServices';
import imagess from '../../assets/icons/imagess.png';
import TransactionData from './components/Partner/TransactionData/Transaction';
import Buses from './components/Partner/Buses/Buses';
import Drivers from './components/Partner/Drivers/Drivers';
import MyBalance from './components/MyBalance/MyBalance';
import Complaints from './components/Complaints/Complaints';
import complaints from '../../assets/icons/Group 40019.png';
interface TabI {
	key: string;
	icon: any;
	content: any;
	title: string;
	role: number[];
}

interface CustomTabsI {
	(t: TranslationFuncType): Array<TabI>;
}

const CustomTabs: CustomTabsI = t => {
	return [
		/* PASSENGER */
		{
			key: '/account',
			icon: user,
			content: <PersonalInfo />,
			title: t('pages.account.personalinfo'),
			role: [4]
		},
		// {
		// 	key: '/account/myBalance',
		// 	icon: myBalance,
		// 	content: <MyBalance />,
		// 	title: t('pages.account.myBalancePage.title'),
		// 	role: [4]
		// },
		{
			key: '/account/tickets',
			icon: ticket,
			content: <MyTickets />,
			title: t('pages.account.tickets'),
			role: [4]
		},
		{
			key: '/account/ticketHolders',
			icon: card,
			content: <TicketHolders />,
			title: t('pages.account.ticketHolders'),
			role: [4]
		},
		{
			key: '/account/paymentHistory',
			icon: wailt,
			content: <PaymentHistory />,
			title: t('pages.account.paymentHistory'),
			role: [4]
		},
		{
			key: '/account/marchHistory',
			icon: way,
			content: <HistoryMarches />,
			title: t('pages.account.marchHistory'),
			role: [4]
		},
		{
			key: '/account/complaints',
			icon: complaints,
			content: <Complaints />,
			title: t('pages.account.complaints'),
			role: [4]
		},
		// {
		// 	key: '/account/notifications',
		// 	icon: notification,
		// 	content: <Notifications />,
		// 	title: t('pages.account.notifications'),
		// 	role: [4]
		// },

		/* PARTNER */
		{
			key: '/partner',
			icon: user,
			content: <PartnerDetails />,
			title: t('pages.account.partner.partnerDetails.title'),
			role: [3]
		},
		{
			key: '/partner/routes',
			icon: way,
			content: <Routes />,
			title: t('pages.account.partner.routes.title'),
			role: [3]
		},
		{
			key: '/partner/buses',
			icon: buses,
			content: <Buses />,
			title: t('pages.account.partner.buses.title'),
			role: [3]
		},
		{
			key: '/partner/drivers',
			icon: drivers,
			content: <Drivers />,
			title: t('pages.account.partner.drivers.title'),
			role: [3]
		},
		// {
		// 	key: '/partner/providedServices',
		// 	icon: group,
		// 	content: <ProvidedServices />,
		// 	title: t('pages.account.partner.providedServices.title'),
		// 	role: [3]
		// },
		{
			key: '/partner/transactions',
			icon: imagess,
			content: <TransactionData />,
			title: t('pages.account.partner.transactions.title'),
			role: [3]
		},
		{
			key: '/partner/transfersHistory',
			icon: wailt,
			content: <TransfersHistory />,
			title: t('pages.account.partner.transfersHistory.transferhistory'),
			role: [3]
		}
	];
};

export default CustomTabs;

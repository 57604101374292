import {maxChar, minChars, numberOnlyRule} from '../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'statusIds',
		label: t('pages.account.ticketHoldersPage.filter.status.title'),
		type: 'select',
		isRequired: false,
		hiddenOptions: [1020,1030],
		colProps: {
			lg: 24,
			xs: 24
		},
		dictionaryApiPath: 'holder-statuses'
	},
	{
		name: 'createdAt',
		label: t('pages.account.ticketHoldersPage.filter.registerDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		}
	},
	{
		name: 'number',
		label: t('pages.account.ticketHoldersPage.filter.ticketHolderSecureCode'),
		type: 'text',
		allowClear: true, // Ensure allowClear is set to true
		rules: [maxChar(t, 16), minChars(t, 16), numberOnlyRule(t)],
		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'} // Set the height to 20px
		}
	}
];

export default fields;

import {Button, Col, Form, Modal, Row, Upload} from 'antd';
import dayjs from 'dayjs';
import {Camera, Trash} from 'phosphor-react'; // Import Trash icon
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {validateMessages} from '../../../../Components/AuthModalForm';
import Toast from '../../../../Components/Toast';
import FormGroup from '../../../../Components/formItems/FormGroup';
import SERVICES from '../../../../services';
import {fetchImage} from '../../../../utils/fetchImage/fetchImage';
import '../PersonalInfo/PersonalInfo.less';
import personalPageFields from './PersoninfoForm';
import passwordFieldss from './passwordFields';
import {cleanFormErrors, updateUserInfoThunkPass} from '../../../../store/account.userInfo.slice';
import {AppDispatch} from '../../../../store';
import _ from 'lodash';
import {deleteUserImageThunk, getUserThunk, updateUserImageThunk} from '../../../../store/auth.slice';
import {useLocation} from 'react-router-dom';

const PersonalInfo = () => {
	const currentPageName = '/account';
	const {pathname} = useLocation();
	const [personalInfoForm] = Form.useForm();
	const [passwordChangeForm] = Form.useForm();
	const [uploadForm] = Form.useForm();
	const {t} = useTranslation();
	const auth = useSelector(({auth}) => auth.data);
	const formErrors = useSelector(({userInfo}) => userInfo.formErrors);
	const [modalVisible, setModalVisible] = useState(false);
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [filesToUpload, setFilesToUpload] = useState<any>([]);
	const [fileList, setFileList] = useState([]);
	const [imageSrc, setImageSrc] = useState('');
	const dispatch = useDispatch<AppDispatch>();
	const [defaultImage, setDefaultImage] = useState('');
	const [disableUploadButton, setDisableUploadButton] = useState(true);

	// useEffect(() => {
	// 	dispatch(getUserThunk());
	// 	console.log('asd');
	// }, []);
	useEffect(() => {
		if (currentPageName === pathname) {
			dispatch(getUserThunk());
		}
	}, [pathname]);

	const beforeUpload = (file: any) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			setUploadError(t('actions.uploadJPG'));
			return false;
		}

		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			setUploadError(t('actions.image2MB'));
			return false;
		} else {
			setUploadError(null);
		}
		setFilesToUpload([...filesToUpload, file]);
		return false;
	};

	const handleFileChange = ({fileList}: any) => {
		setDefaultImage('');
		setDisableUploadButton(false);
		setFileList(fileList);
	};

	const handleRemove = (e: any) => {
		auth.avatar && setDisableUploadButton(false);
		e.stopPropagation();
		setDefaultImage(`${auth?.firstName[0]}${auth?.lastName[0]}`);
		setFileList([]);
		setImageSrc('');
		setFilesToUpload([]);
	};

	const handlePersonalInfoFinish = ({email, ...values}: any) => {
		SERVICES.USER.updateUser(values, auth.id)
			.then(() => {
				Toast({type: 'success', description: t('messages.success.success')});
			})
			.catch(() => {
				Toast({type: 'error', description: t('messages.error.badRequest')});
			});
	};

	const handlePasswordChangeFinish = (values: {currentPassword: any; password: any; rePassword: any}) => {
		dispatch(updateUserInfoThunkPass(values)).then(action => {
			if (action.type.endsWith('fulfilled')) {
				Toast({type: 'success', description: t('messages.success.success')});
				setModalVisible(false);
				passwordChangeForm.resetFields();
			} else {
				setModalVisible(true);
			}
		});
	};

	const handlePersonalInfoClick = () => {
		personalInfoForm.submit();
	};

	const handleUploadClick = async () => {
		if (!filesToUpload.length) {
			dispatch(deleteUserImageThunk(auth.id))
				.unwrap()
				.then(() => {
					setFileList([]);
					setImageSrc('');
					setDisableUploadButton(true);
					setFilesToUpload([]);
					Toast({type: 'success', description: t(`messages.success.success`)});
				})
				.catch(() => {
					Toast({type: 'error', description: t(`messages.error.badRequest`)});
				});
		} else {
			// @ts-ignore
			dispatch(updateUserImageThunk({id: auth.id, image: fileList[0].originFileObj}))
				.unwrap()
				.then(() => {
					Toast({type: 'success', description: t(`messages.success.success`)});
					setDisableUploadButton(true);
					setFilesToUpload([]);
				})
				.catch(() => {
					Toast({type: 'error', description: t(`messages.error.badRequest`)});
				});
		}
	};

	const handleOk = async () => {
		try {
			await passwordChangeForm.validateFields();
			passwordChangeForm.submit();
		} catch (error) {
			console.error('Validation failed:', error);
		}
	};

	const handleCancel = () => {
		setModalVisible(false);
		passwordChangeForm.resetFields();
		if (Object.keys(formErrors).length) dispatch(cleanFormErrors());
	};

	const onFormValuesChange = (changedValues: any) => {
		if (_.has(changedValues, 'currentPassword') && Object.keys(formErrors).length)
			dispatch(cleanFormErrors());
	};

	const uploadButton = (
		<div>
			<div style={{position: 'relative'}} className="upload-images">
				{auth.avatar ? (
					<img alt="" src={auth.avatar} />
				) : (
					<div
						className="name"
						style={{userSelect: 'none'}}>{`${auth?.firstName[0]}${auth?.lastName[0]}`}</div>
				)}
				<Camera size={22} weight="light" className="camera" />
				<Trash size={22} weight="light" className="delete-icon" onClick={handleRemove} />
			</div>
		</div>
	);
	useEffect(() => {
		fetchImage(auth.avatar, setImageSrc);

		return () => {
			URL.revokeObjectURL(imageSrc);
		};
	}, [auth.avatar]);

	return (
		<div className="oll-form-div" style={{paddingTop: 23}}>
			<div className="top-data__rows">
				<div className="top-data__row">
					{t('pages.account.myBalancePage.top.userID', {id: auth.identityNumber})}
				</div>
				<div className="top-data__row">
					{t('pages.account.myBalancePage.top.balance', {count: auth.balance})}
				</div>
			</div>
			<Form
				onFinish={handlePersonalInfoFinish}
				form={personalInfoForm}
				className="form-person-info"
				layout="vertical"
				validateTrigger="onBlur"
				initialValues={{
					firstName: auth.firstName,
					lastName: auth.lastName,
					email: auth.email,
					phoneNumber: auth.phoneNumber,
					genderId: auth.genderId,
					dob: dayjs(auth.dob)
				}}
				validateMessages={validateMessages(t)}>
				<FormGroup
					tabFields={personalPageFields(t)}
					formErrors={formErrors}
					form={personalInfoForm}
				/>
				<Row>
					<Col xs={24} lg={24}>
						<div className="button-form">
							<Button type="default" onClick={handlePersonalInfoClick}>
								{t('actions.save')}
							</Button>
						</div>
					</Col>
				</Row>
			</Form>

			<Form
				onFinish={handlePasswordChangeFinish}
				className="form-person-info"
				validateMessages={validateMessages(t)}>
				<Row>
					<Col xs={24} lg={24}>
						<div className="form-person-info-password">
							<p className="labels">{t('fields.password')}</p>
							<div className="password-btn">
								<Button type="default" onClick={() => setModalVisible(true)}>
									{t('actions.changepassword')}
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				<Modal
					destroyOnClose
					className="customModal"
					title={t('actions.changepassword')}
					open={modalVisible}
					onOk={handleOk}
					okText={t('actions.save')}
					onCancel={handleCancel}>
					<Form
						onFinish={handlePasswordChangeFinish}
						form={passwordChangeForm}
						layout="vertical"
						onValuesChange={onFormValuesChange}
						validateMessages={validateMessages(t)}>
						<FormGroup
							tabFields={passwordFieldss(t)}
							formErrors={formErrors}
							form={passwordChangeForm}
						/>
					</Form>
				</Modal>
			</Form>

			<Form
				form={uploadForm}
				className="form-uploud"
				layout="vertical"
				validateMessages={validateMessages(t)}>
				<div className="upload">
					<p className="chang-images">{t('pages.account.personalinfopage.changeimages')}</p>
					<Upload
						fileList={fileList}
						name="avatar"
						listType="picture-circle"
						className="avatar-uploader"
						showUploadList={false}
						accept="image/*"
						maxCount={1}
						multiple={false}
						beforeUpload={beforeUpload}
						onChange={handleFileChange}>
						{fileList.length > 0 || auth?.avatar ? (
							<div style={{position: 'relative'}} className="upload-images">
								{defaultImage ? (
									<div
										className="name"
										style={{
											userSelect: 'none'
										}}>{`${auth?.firstName[0]}${auth?.lastName[0]}`}</div>
								) : (
									<img
										className="upload-images"
										src={
											fileList.length > 0
												? // @ts-ignore
												  URL.createObjectURL(fileList[0].originFileObj)
												: imageSrc
										}
										alt={`Selected File`}
									/>
								)}
								<Camera size={22} weight="light" className="camera" />
								<Trash
									size={22}
									weight="light"
									className="delete-icon"
									onClick={handleRemove}
								/>
							</div>
						) : (
							uploadButton
						)}
					</Upload>
				</div>

				{uploadError && (
					<div className="upload-error" style={{color: 'red'}}>
						{uploadError}
					</div>
				)}
				<div className="upload-button">
					<Button
						type="default"
						onClick={handleUploadClick}
						disabled={disableUploadButton}
						className={!disableUploadButton ? 'green-button' : 'gray-button'}>
						{t('actions.save')}
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default PersonalInfo;

import {Button, Statistic} from 'antd';
import {maxChar, minChars, numberOnlyRule} from '../../../utils/rules/validation';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';
import {resendOtpThunk} from '../../../store/auth.slice';
import {useEffect, useState} from 'react';

interface ForgotPassTypes {
	(
		t: TranslationFuncType,
		dispatch: (data: any) => void,
		isSuccess?: boolean,
		otpEmail?: string | null
	): ModalFormFieldTypes[];
}

const fields: ForgotPassTypes = (t, dispatch, isSuccess, otpEmail) => [
	{
		type: 'customElement',
		component: () => {
			return (
				<div className="auth-component__forgotPass">
					<div className="auth-component__forgotPass-main">
						{t('messages.pleaseVerifyYourEmail')}
					</div>
				</div>
			);
		},
		colProps: {
			xs: 24
		}
	},
	{
		label: t('header.verificationCode'),
		name: 'otp',
		type: 'text',
		rules: [numberOnlyRule(t), maxChar(t, 6), minChars(t, 6)],
		colProps: {
			xs: 24
		}
	}

	// {
	// 	type: 'customElement',
	// 	component: () => {
	// 		const deadline = Date.now() + 1000 * 5;
	// 		let isDisabled = true;
	// 		const enableButton = () => {
	// 			isDisabled = false;
	// 		};
	// 		return (
	// 			<div className="auth-component__forgotPass">
	// 				<div className="auth-component__forgotPass-main">
	// 					<Button
	// 						onClick={() => dispatch(resendOtpThunk({email: otpEmail}))}
	// 						disabled={isDisabled}>
	// 						Resend Code
	// 					</Button>
	// 					<Statistic.Countdown value={deadline} onFinish={enableButton} />
	// 				</div>
	// 			</div>
	// 		);
	// 	},
	// 	colProps: {
	// 		xs: 24
	// 	}
	// }
];
export default fields;

import API from '../api';
import axiosClient from '../utils/axiosClient';

const getpartnerTransfers = async (data: any) => {
	const res = await axiosClient.get(API.PARTNERTRANSFER.partnerTransfers, {
		params: {...data.filter, page: data.page, take: data.take}
	});
	return res?.data;
};

const PARTNERTRANSFER = {
	getpartnerTransfers
};

export default PARTNERTRANSFER;

enum AUTH {
	signIn = '/api/v1/auth/sign-in',
	signUp = '/api/v1/auth/sign-up',
	signOut = '/api/v1/auth/sign-out',
	resetPassword = '/api/v1/auth/reset-password',
	setPassword = '/api/v1/auth/set-password',

	resendEmail = '/api/v1/auth/email-confirmation/resend',
	signInWithEmail = '/api/v1/auth/sign-in/email'
}

export default AUTH;

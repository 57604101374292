import {getKeyByLang} from '../../../../../utils/heplerFuncs/functions';
import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const fields: ModalFormFieldTypesFunc = (t, {cityId}) => [
	{
		name: getKeyByLang('fullName'),
		label: t('pages.account.partner.drivers.titles'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'routeNumber',
		label: t('pages.account.partner.routes.routeItem.busNum'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		customApiFetch: {
			path: `api/v1/routes/public?cityId=${cityId}`,
			labelKey: 'number',
			valueKey: (val: any) => {
				return `${val.id}_${val.number}` as string;
			}
		}
	},

	{
		name: 'transportYellowLicensePlate',
		label: t('pages.account.partner.buses.titles'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/partners/transports',
			labelKey: val => val.yellowLicensePlate,
			valueKey: 'yellowLicensePlate'
		}
	},
	{
		name: 'driversLicenseNumber',
		label: t('pages.account.partner.routes.popups.drivers.driversLicenseNumber'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'workContract',
		label: t('pages.account.partner.routes.popups.drivers.employmentContractNumber'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'} // Set the height to 20px
		}
	},
	{
		name: 'expirationOfDriversLicense',
		label: t('pages.account.partner.drivers.filter.driversLicenseEndDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'issuanceOfDriversLicense',
		label: t('pages.account.partner.drivers.filter.driversLicenseStartDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'statusId',
		label: t('pages.account.partner.transactions.transferstatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		},
		dictionaryApiPath: 'driver-statuses'
	}
];
export default fields;

import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export function usePagination(data: [], itemsPerPage: number = 6) {
	const [currentPage, setCurrentPage] = useState<number | any>(1);
	const [take, setTake] = useState<number | any>(10);

	const navigate = useNavigate();
	const location = useLocation();
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = data?.slice(startIndex, endIndex);
	const currentItemsData = data;
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		navigate({search: params.toString()});
	}, [currentPage, navigate]);

	const goToPage = (page: number) => {
		setCurrentPage(page);
	};

	return {
		currentItemsData,
		currentItems,
		currentPage,
		take,
		goToPage
	};
}

import {setAuthModalVisible} from '../../../store/auth.slice';
import {latinRulesWithNumber} from '../../../utils/rules/validation';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';

interface FieldsType {
	(t: TranslationFuncType, dispatch: (data: any) => void, isLoading?: boolean): ModalFormFieldTypes[];
}

const fields: FieldsType = (t, dispatch, isLoading) => {
	return [
		{
			name: 'logIn',
			type: 'group',
			className: 'formGroup',
			colProps: {
				xs: 24
			},
			inputs: [
				{
					label: t('fields.email'),
					name: 'email',
					disabled: isLoading,
					type: 'text',
					rules: [{type: 'email'}, latinRulesWithNumber(t)],
					colProps: {
						xs: 24
					}
				},
				{
					label: t('fields.password'),
					name: 'password',
					disabled: isLoading,

					type: 'password',
					className: 'passwordInput',
					rules: [{type: 'password'}],
					colProps: {
						xs: 24
					}
				}
			]
		},
		{
			type: 'customElement',
			component: () => {
				return (
					<div className="auth-component-resetPass">
						<span
							onClick={() => {
								dispatch(setAuthModalVisible({visible: 'SIGN_IN_WITH_EMAIL'}));
							}}>
							{t('header.signInWithEmail')}
						</span>
					</div>
				);
			},
			colProps: {
				xs: 24
			}
		},
		{
			type: 'customElement',
			component: () => {
				return (
					<div className="auth-component-resetPass">
						<span
							onClick={() => {
								dispatch(setAuthModalVisible({visible: 'FOGOT_PASSWORD'}));
							}}>
							{t('header.resetPassword')}
						</span>
					</div>
				);
			},
			colProps: {
				xs: 24
			}
		},

		{
			type: 'customElement',
			component: () => {
				return (
					<div className="auth-component">
						{t('header.notRegisteredYet')}
						<span
							onClick={() => {
								dispatch(setAuthModalVisible({visible: 'SIGN_UP'}));
							}}>
							{t('header.sign_up')}
						</span>
					</div>
				);
			},
			colProps: {
				xs: 24
			}
		}
	];
};

export default fields;

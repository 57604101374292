import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Divider} from 'antd';
import './HistoryMarches.less';
import i18n from '../../../../utils/translate/i18';
import {getKeyByLang} from '../../../../utils/heplerFuncs/functions';
function HistoryMarchesData({options}: any) {
	let {t} = useTranslation();
	console.log(options.tariffTypeId);
	return (
		<div className="history-marches__item">
			<div className="history-marches__row">
				<div className="history-marches__top" style={{padding: 0}}>
					<div className="history-marches__number">
						<span>{moment(options.transactionDate).format('DD')} </span>
						<span>{t(`months.${moment(options.transactionDate).month()}`)} </span>
						<span>{moment(options.transactionDate).format('YYYY')} </span>
						<span>{moment(options.transactionDate).format('HH:mm')} </span>
					</div>
				</div>
				<Divider style={{background: '#009E83'}} />
				<div className="history-marches__info">
					<div className="history-marches__info-row">
						<div className="history-marches__info-item">
							<div className="history-marches__info-title">
								<p>{t('pages.account.partner.routes.routeItem.city')}</p>
							</div>
							<div className="history-marches__info-main">
								<p>{options.city && options.city[i18n.language]}</p>
							</div>
						</div>
						<div className="history-marches__info-item">
							<div className="history-marches__info-title">
								<p className="title">{t('pages.account.historyMarches.marchestitle')}</p>
							</div>
							<div className="history-marches__info-main">
								<p className="text-">{options.routeNumber}</p>
							</div>
						</div>
						<div className="history-marches__info-item">
							<div className="history-marches__info-title">
								{t('pages.account.historyMarches.product')}
							</div>
							<div className="history-marches__info-main">
								<span> {options.tariffNameHy && options[getKeyByLang('tariffName')]}</span>
							</div>
						</div>
						<div className="history-marches__info-item">
							<div className="history-marches__info-title">
								{t('pages.account.paymentHistoryPage.filter.type.title')}
							</div>
							<div className="history-marches__info-main">
								<span>{options.tariffType && options.tariffType[i18n.language]}</span>
							</div>
						</div>
						<div className="history-marches__info-item">
							<div className="history-marches__info-title">
								{t('pages.account.historyMarches.carriertitle')}
							</div>
							<div className="history-marches__info-main">
								<span>{options.holderNumber && options.holderNumber}</span>
							</div>
						</div>
						<div className="history-marches__info-item">
							<div className="history-marches__info-title">
								{t('pages.account.partner.transactions.amount')}
							</div>
							<div className="history-marches__info-main">
								<span>
									{options.amount ? options.amount.slice(0, -3) : ''}{' '}
									{options.tariffTypeId === 1
										? t('pages.account.historyMarches.count')
										: t('AMD')}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HistoryMarchesData;

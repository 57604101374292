import {maxChar, minChars, numberOnlyRule} from '../../../utils/rules/validation';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';

interface ForgotPassTypes {
	(
		t: TranslationFuncType,
		dispatch: (data: any) => void,
		isSuccess?: boolean,
		otpEmail?: string | null
	): ModalFormFieldTypes[];
}

const fields: ForgotPassTypes = (t, dispatch, isSuccess) => [
	{
		type: 'customElement',
		component: () => {
			return (
				<div className="auth-component__forgotPass">
					<div className="auth-component__forgotPass-main">
						{t('messages.pleaseVerifyYourEmail')}
					</div>
				</div>
			);
		},
		colProps: {
			xs: 24
		}
	},
	{
		label: t('header.verificationCode'),
		name: 'otp',
		type: 'text',
		rules: [numberOnlyRule(t), maxChar(t, 6), minChars(t, 6)],
		colProps: {
			xs: 24
		}
	}

	// {
	// 	type: 'customElement',
	// 	component: () => {
	// 		return (
	// 			<div className="auth-component__forgotPass">
	// 				<div className="auth-component__forgotPass-main">Resend Code</div>
	// 			</div>
	// 		);
	// 	},
	// 	colProps: {
	// 		xs: 24
	// 	}
	// }
];
export default fields;

import {setAuthModalVisible} from '../../../store/auth.slice';
import {latinRulesWithNumber} from '../../../utils/rules/validation';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';

interface FieldsType {
	(t: TranslationFuncType, dispatch: (data: any) => void, isLoading?: boolean): ModalFormFieldTypes[];
}

const fields: FieldsType = (t, dispatch, isLoading) => {
	return [
		{
			label: t('fields.email'),
			name: 'email',
			type: 'text',
			rules: [{type: 'email'}, latinRulesWithNumber(t)],
			colProps: {
				xs: 24
			}
		}
	];
};

export default fields;

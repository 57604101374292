import API from '../api';
import axiosClient from '../utils/axiosClient';

// const getNews = async (data: Record<string, any>) => {
// 	const res = await axiosClient.get(API.NEWS.news, {params: {page: data.page, take:data.take, ...data,  }});
// 	return res?.data;
// };
const getNews = async (data: { page: number; take:number}) => {
	try {
		const res = await axiosClient.get(`${API.NEWS.news}`, {
			params: {
				...data,
				page: data.page,
				take:data.take
			}
		});
		return res.data;
	} catch (error) {
		throw error;
	}
};
const getNewsByID = async (id: number, data: Record<string, any>) => {
	const res = await axiosClient.get(API.NEWS.newsByID + id, data);
	return res?.data;
};

const NEWS = {
	getNews,
	getNewsByID
};

export default NEWS;

import API from '../api';
import axiosClient from '../utils/axiosClient';

const getforgotPassword = async (data: any) => {
	const res = await axiosClient.post(API.FORGOTPASSWORD.forgotPassword, data);
	return res?.data;
};

const FORGOTPASSWORD = {
	getforgotPassword
};

export default FORGOTPASSWORD;

import {ArrowRight} from 'phosphor-react';
import {useTranslation} from 'react-i18next';
import {getKeyByLang} from '../../../utils/heplerFuncs/functions';
const ScheduleStops = ({data, hasNext, startPoint, endPoint}: any) => {
	let {t} = useTranslation();
	return (
		<div
			className="moreinfo__stops"
			style={{
				flexGrow: 0,
				flexShrink: 0,
				flexBasis: 'auto',
				display: 'flex',
				marginBottom: 10
			}}>
			<div
				className="moreinfo__stop"
				style={data ? {fontWeight: data.id === startPoint || data.id === endPoint ? 700 : 500} : {}}>
				{data[getKeyByLang('name')] ?? ''}
			</div>
			{hasNext ? (
				<div className="moreinfo__sto" style={{padding: '0px 5px'}}>
					<ArrowRight size={20} color="#009E83" />
				</div>
			) : null}
		</div>
	);
};

export default ScheduleStops;

import {getKeyByLang} from '../../../../utils/heplerFuncs/functions';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: getKeyByLang('tariffName'),
		label: t('pages.account.myTickets.filter.filterBy.ticketName'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'holderTypeId',
		label: t('pages.tickets.oneUse.ticketCard.storage'),
		type: 'select',
		dictionaryApiPath: 'tariff-holder-types',
		colProps: {
			lg: 12,
			xs: 24
		},
		isRequired: false
	},
	{
		name: 'createdAt',
		label: t('pages.tickets.oneUse.ticketCard.purchaseDate'),
		type: 'dateRange',
		colProps: {
			lg: 12,
			xs: 24
		},
		isRequired: false
	},
	{
		name: 'expiredAt',
		label: t('pages.account.myTickets.filter.filterBy.validDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	// {
	// 	name: 'isValid',
	// 	label: t('pages.account.myTickets.filter.filterBy.valid.title'),
	// 	type: 'select',
	// 	isRequired: false,
	// 	colProps: {
	// 		lg: 12,
	// 		xs: 24
	// 	},
	// 	selectProps: {
	// 		options: [
	// 			{label: t('pages.account.myTickets.filter.filterBy.valid.options.valid'), value: true},
	// 			{label: t('pages.account.myTickets.filter.filterBy.valid.options.invalid'), value: false}
	// 		]
	// 	}
	// },
	{
		name: 'status',
		label: t('pages.account.partner.transactions.transferstatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		selectProps: {
			options: [
				{label: t('pages.account.myTickets.filter.filterBy.valid.options.valid'), value: 'valid'},
				{label: t('pages.account.myTickets.used'), value: 'used'},
				{label: t('pages.account.myTickets.inValid'), value: 'expired'}
			]
		}
	}
];
export default fields;

import {useTranslation} from 'react-i18next';
import DataExport from '../../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import './TransfersHistory.less';
import {useDispatch, useSelector} from 'react-redux';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import {useEffect, useState} from 'react';
import TransfersHistoryData from './TransfersHistoryData';
import {AppDispatch} from '../../../../../store';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import {getPartnerTransfers} from '../../../../../store/partner.transferHistory.slice';
import {Empty} from 'antd';
import {useLocation} from 'react-router-dom';
import dayjs from 'dayjs';
import i18n from '../../../../../utils/translate/i18';
import providedServicesform from './TransfersHistoryform';

const TransfersHistory = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const {pathname} = useLocation();
	const currentPageName = '/partner/transfersHistory';
	const auth = useSelector((state: any) => state.auth.data);
	const transfersHistory = useSelector((state: any) => state.transfersHistory.data);
	const [pageSize, setPageSize] = useState(4);
	const {currentItemsData, currentPage, goToPage} = usePagination(
		transfersHistory?.items,
		transfersHistory?.pageSize
	);

	const [info, setInfo] = useState<any>(null);

	useEffect(() => {
		if (currentPageName === pathname) {
			dispatch(getPartnerTransfers({page: currentPage, filter: info, take: pageSize}));
		}
	}, [auth.id, currentPage, info, pathname, pageSize]);

	const columns = [
		{dataIndex: 'transferDate', title: t('pages.account.partner.transactions.timePeriod')},
		{dataIndex: 'status', title: t('pages.account.partner.transactions.transferstatus')},
		{dataIndex: 'periodStartDate', title: t('pages.account.partner.transfersHistory.periodStartDate')},
		{dataIndex: 'periodEndDate', title: t('pages.account.partner.transfersHistory.periodEndDate')},
		{dataIndex: 'totalAmount', title: t('pages.account.partner.transactions.amount')},
		{dataIndex: 'transferNumber', title: t('pages.account.partner.transfersHistory.transferNumber')},
		{dataIndex: 'notes', title: t('pages.account.partner.transfersHistory.notice')}
	];

	const rows = currentItemsData?.map((transaction: any) => ({
		transferDate: dayjs(transaction.transferDate).format('DD-MM-YYYY HH:mm').toString(),
		status: transaction?.status ? transaction?.status[i18n.language] : ' ',
		periodStartDate: dayjs(transaction.periodStartDate).format('DD-MM-YYYY').toString(),
		periodEndDate: dayjs(transaction.periodEndDate).format('DD-MM-YYYY').toString(),
		totalAmount: transaction?.totalAmount,
		transferNumber: transaction?.transferNumber,
		notes: transaction?.notes
	}));

	return (
		<div className="transfers-history-box-all">
			<div className="partner-routes__container">
				<div className="partner-routes__content">
					<div className="partner-routes__top">
						<DataExport exportData={{rows, columns}} />
						<DefaultWrapper
							filteritems={providedServicesform}
							defaultFilters={info}
							applyFilters={(data: any) => setInfo(data)}
						/>
					</div>

					{currentItemsData && currentItemsData.length > 0 ? (
						<div>
							<div className="partner-drivers__row">
								<div className="route-drivers">
									<div className="route-drivers-container">
										<div className="route-drivers-content">
											<div className="route-drivers-row">
												{currentItemsData?.map((item: any) => (
													<TransfersHistoryData key={item.id} options={item} />
												))}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div style={{marginTop: 20}}>
								<PagePagination
									pageSizeOptions={[4, 10, 20, 50, 100]}
									pageSize={transfersHistory.pageSize}
									currentPage={transfersHistory.currentPage}
									totalItems={transfersHistory.totalCount}
									onPageChange={goToPage}
									onTakeChange={size => {
										setPageSize(size);
										goToPage(1); // Reset to the first page when page size changes
									}}
								/>
							</div>
						</div>
					) : (
						<div style={{padding: '50px 0px'}}>
							<Empty />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TransfersHistory;

import {useDispatch, useSelector} from 'react-redux';
import DataExport from '../../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import RouteDrivers from '../Routes/RouteItem/RouteDrivers/RouteDrivers';
import './Drivers.less';
import filteritems from './filter.form';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import {AppDispatch} from '../../../../../store';
import {useEffect, useState} from 'react';
import {getPartnerDrivers} from '../../../../../store/partner.drivers.slice';
import {Empty} from 'antd';
import dayjs from 'dayjs';
import i18n from '../../../../../utils/translate/i18';
import {t} from 'i18next';
import {getKeyByLang} from '../../../../../utils/heplerFuncs/functions';
import {useLocation} from 'react-router-dom';

function Drivers() {
	const dispatch = useDispatch<AppDispatch>();
	const {pathname} = useLocation();
	const currentPageName = '/partner/drivers';
	const drivers = useSelector(({drivers}) => drivers.data);
	const auth = useSelector((state: any) => state.auth.data);
	const [pageSize, setPageSize] = useState(4);
	const {currentItemsData, currentPage, goToPage} = usePagination(drivers.items, drivers.pageSize);
	const [info, setInfo] = useState<any>(null);
	useEffect(() => {
		let newValues: Record<string, any> = {};
		if (Object.keys(info ?? {})?.length) {
			newValues = {...info};
			if (newValues.cityId) {
				delete newValues.cityId;
			}
			if (info?.routeNumber) {
				newValues.routeNumber = info.routeNumber.split('_')[1];
			}
		}
		if (currentPageName === pathname) {
			dispatch(getPartnerDrivers({page: currentPage, filter: newValues, take: pageSize}));
		}
	}, [auth.id, currentPage, info, pathname, pageSize]);

	const columns = [
		{dataIndex: 'fullName', title: t('pages.account.partner.drivers.titles')},
		{dataIndex: 'phoneNumber', title: t('pages.account.partner.routes.popups.drivers.phone')},
		{dataIndex: 'number', title: t('pages.account.partner.routes.popups.drivers.march')},
		{dataIndex: 'yellowLicensePlate', title: t('pages.account.partner.buses.titles')},
		{
			dataIndex: 'driversLicenseNumber',
			title: t('pages.account.partner.routes.popups.drivers.driversLicenseNumber')
		},
		{
			dataIndex: 'issuanceOfDriversLicense',
			title: t('pages.account.partner.routes.popups.drivers.drivingLicenseDate')
		},
		{
			dataIndex: 'expirationOfDriversLicense',
			title: t('pages.account.partner.routes.popups.drivers.drivingLicenseExpireDate')
		},
		{
			dataIndex: 'workContract',
			title: t('pages.account.partner.routes.popups.drivers.employmentContractNumber')
		},
		{
			dataIndex: 'status',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title')
		}
	];

	const rows = currentItemsData?.map((transaction: any) => {
		return {
			fullName: transaction[getKeyByLang('fullName')],
			phoneNumber: transaction?.phoneNumber,
			number: transaction?.transport?.route?.number,
			yellowLicensePlate: transaction?.transport?.yellowLicensePlate,
			driversLicenseNumber: transaction?.driversLicenseNumber,
			issuanceOfDriversLicense: dayjs(transaction?.issuanceOfDriversLicense).format('DD-MM-YYYY'),
			expirationOfDriversLicense: dayjs(transaction?.expirationOfDriversLicense).format('DD-MM-YYYY'),
			workContract: transaction?.workContract,
			status: transaction?.status[i18n.language]
		};
	});

	return (
		<div className="partner-drivers">
			<div className="partner-drivers__container">
				<div className="partner-drivers__content">
					<div className="partner-drivers__top">
						<DataExport exportData={{rows, columns}} />
						<DefaultWrapper
							filteritems={filteritems}
							defaultFilters={info}
							applyFilters={(data: any) => {
								setInfo(data);
							}}
							customFilters={{cityId: auth.cityId}}
						/>
					</div>

					{currentItemsData && currentItemsData.length > 0 ? (
						<>
							<div className="partner-drivers__row">
								<div className="route-drivers">
									<div className="route-drivers-container">
										<div className="route-drivers-content">
											<div className="route-drivers-row">
												{currentItemsData?.map((elem: any) => (
													<RouteDrivers key={elem.id} options={elem} />
												))}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div >
								<PagePagination
									pageSizeOptions={[4, 10, 20, 50, 100]}
									pageSize={drivers.pageSize}
									currentPage={drivers.currentPage}
									totalItems={drivers.totalCount}
									onPageChange={goToPage}
									onTakeChange={size => {
										setPageSize(size);
										goToPage(1); // Reset to the first page when page size changes
									}}
								/>
							</div>
						</>
					) : (
						<div style={{padding: '50px 0px'}}>
							<Empty />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Drivers;

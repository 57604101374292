enum AUTHOTP {
	// WHEN FORGOT PASSWORD OR OTHER CASE EXCEPT SIGNING UP
	authOtp = 'api/v1/auth/forgot-password/otp',
	// WHEN SIGNING UP ONLY
	confirmEmailOtp = 'api/v1/auth/email-confirmation',
	// WHEN SIGNING IN BY EMAIL ONLY
	verifySignInWithEmailOtp = 'api/v1/auth/sign-in/otp'
}

export default AUTHOTP;

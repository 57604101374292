import './PartnerDetails.less';
import phoneIco from './../../../../../assets/icons/phone.svg';
import mailIco from './../../../../../assets/icons/mail.svg';
import {Button, Form, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {validateMessages} from '../../../../../Components/AuthModalForm';
import passwordFields from '../../PersonalInfo/passwordFields';
import FormGroup from '../../../../../Components/formItems/FormGroup';
import {useForm} from 'antd/es/form/Form';
import {useDispatch, useSelector} from 'react-redux';
import i18n from '../../../../../utils/translate/i18';
import Toast from '../../../../../Components/Toast';
import {getKeyByLang} from '../../../../../utils/heplerFuncs/functions';
import {cleanFormErrors, updatePartnerInfoThunkPass} from '../../../../../store/partner.slice';
import {AppDispatch} from '../../../../../store';
import _ from 'lodash';

const PartnerDetails = () => {
	const {t} = useTranslation();
	const [passwordChangeForm] = Form.useForm();
	const [form] = useForm();
	const [modalVisible, setModalVisible] = useState(false);
	const auth = useSelector(({auth}) => auth.data);
	const dispatch = useDispatch<AppDispatch>();
	const formErrors = useSelector(({partners}) => partners.formErrors);

	const partnerDetailsInfo = [
		{
			id: 1,
			title: t('pages.account.partner.partnerDetails.info.CompanyName'),
			info: auth[getKeyByLang('companyName')]
		},
		{
			id: 2,
			title: t('pages.account.partner.partnerDetails.info.CompanyForm'),
			info: auth.companyType[i18n.language]
		},
		{
			id: 3,
			title: t('pages.account.partner.partnerDetails.info.AVC'),
			info: auth.taxIdentificationNumber
		},
		{
			id: 4,
			title: t('pages.account.partner.partnerDetails.info.DirectorNameSurname'),
			info: `${auth.firstName} ${auth.lastName}`
		},
		{id: 5, title: t('pages.account.partner.partnerDetails.info.License'), info: auth.licenseNumber}
	];

	const partnerDetailsContact = [
		{
			title: t('pages.account.partner.partnerDetails.contact.phone'),
			info: [auth.phoneNumber, auth.phoneNumberOther],
			ico: phoneIco
		},
		{
			title: t('pages.account.partner.partnerDetails.contact.email'),
			info: [auth.email],
			ico: mailIco
		},
		{
			title: t('pages.account.partner.partnerDetails.contact.businessAddress'),
			info: [auth.businessAddress]
		},
		{
			title: t('pages.account.partner.partnerDetails.contact.legalAddress'),
			info: [auth.legalAddress]
		}
	];

	const partnerDetailsBank = [
		{title: t('pages.account.partner.partnerDetails.bank.title'), info: auth.partnerBank},
		{title: t('pages.account.partner.partnerDetails.bank.accountNum'), info: auth.bankAccountNumber}
	];

	const handleOk = async () => {
		try {
			await passwordChangeForm.validateFields();
			passwordChangeForm.submit();
		} catch (error) {
			console.error('Validation failed:', error);
		}
	};

	const handleCancel = () => {
		setModalVisible(false);
		passwordChangeForm.resetFields();
		if (Object.keys(formErrors).length) dispatch(cleanFormErrors());
	};

	const handlePasswordChangeFinish = (values: {currentPassword: any; password: any; rePassword: any}) => {
		dispatch(updatePartnerInfoThunkPass(values)).then(action => {
			if (action.type.endsWith('fulfilled')) {
				Toast({type: 'success', description: t('messages.success.success')});
				setModalVisible(false);
				passwordChangeForm.resetFields();
			} else {
				setModalVisible(true);
			}
		});
	};

	const onFormValuesChange = (changedValues: any) => {
		if (_.has(changedValues, 'currentPassword') && Object.keys(formErrors).length)
			dispatch(cleanFormErrors());
	};

	return (
		<div className="partner-details">
			<div className="partner-details__container">
				<div className="partner-details__content">
					<div className="partner-details__row">
						<div className="partner-details__info">
							{partnerDetailsInfo.map((elem, index) => (
								<div key={index} className="info__container">
									<div className="info__row">
										<div className="info__title">
											<p>{elem.title}</p>
										</div>
										<div className="info__main">
											<p>{elem.info ? elem.info : '-'}</p>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className="partner-details__contact">
							{partnerDetailsContact.map((elem, index) => (
								<div key={index} className="info__container">
									<div className="info__row">
										<div className="info__title">
											<p>{elem.title}</p>
										</div>
										<div className="info__main">
											{elem.ico && <img src={elem.ico} alt="icon" />}
											<p>{elem.info.join(', ')}</p>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className="partner-details__bank">
							{partnerDetailsBank.map((elem, index) => (
								<div key={index} className="info__container">
									<div className="info__row">
										<div className="info__title">
											<p>{elem.title}</p>
										</div>
										<div className="info__main">
											<p>{elem.info}</p>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className="partner-details__change-pass">
							<div className="info__container">
								<div className="info__row">
									<div className="info__title">
										<p>{t('pages.account.partner.partnerDetails.changePass.title')}</p>
									</div>
									<Button type="default" onClick={() => setModalVisible(true)}>
										{t('pages.account.partner.partnerDetails.changePass.btn')}
									</Button>
									<Modal
										destroyOnClose
										className="customModal"
										title={t('actions.changepassword')}
										open={modalVisible}
										onOk={handleOk}
										okText={t('actions.save')}
										onCancel={handleCancel}>
										<Form
											onFinish={handlePasswordChangeFinish}
											form={passwordChangeForm}
											layout="vertical"
											onValuesChange={onFormValuesChange}
											validateMessages={validateMessages(t)}>
											<FormGroup
												tabFields={passwordFields(t)}
												formErrors={formErrors}
												form={passwordChangeForm}
											/>
										</Form>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PartnerDetails;

import {notification} from 'antd';
import {CheckCircle, XCircle} from 'phosphor-react';

let notificationQueue: Array<any> = [];
let isQueueProcessing = false;

const processQueue = () => {
	if (notificationQueue.length === 0) {
		isQueueProcessing = false;
		return;
	}

	const {key, options} = notificationQueue.shift();
	notification.open({
		...options,
		key,
		onClose: processQueue
	});
};

const toast = ({
	text,
	type,
	description,
	placement = 'topRight',
	duration = 8
}: {
	text?: string;
	description?: string;
	type: 'success' | 'error';
	placement?: any;
	duration?: number;
}) => {
	const key = `notification_${Date.now()}`;
	const icon =
		type === 'success' ? (
			<CheckCircle color="#009E83" size={30} weight="fill" />
		) : (
			<XCircle color="#CE1126" size={30} weight="fill" />
		);

	const onClose = () => {
		const index = notificationQueue.findIndex(item => item.key === key);
		if (index !== -1) {
			notificationQueue.splice(index, 1);
		}
	};

	const notificationOptions = {
		message: text,
		description: (
			<div style={{whiteSpace: 'normal', maxWidth: '250px', overflowWrap: 'break-word'}}>
				{description}
			</div>
		),
		placement,
		duration,
		icon,
		onClose
	};

	notificationQueue.push({key, options: notificationOptions});

	if (!isQueueProcessing) {
		isQueueProcessing = true;
		processQueue();
	}
};

export default toast;

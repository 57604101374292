import API from '../api';
import axiosClient from '../utils/axiosClient';

const getresetPassword = async (data: any) => {
	const res = await axiosClient.post(API.RESETPASSWORD.resetPassword, data);
	return res?.data;
};

const RESETPASSWORD = {
	getresetPassword
};

export default RESETPASSWORD;

import API from '../api';
import axiosClient from '../utils/axiosClient';

const getPartnerTransactions = async (data: any) => {
	const res = await axiosClient.get(API.PARTNERTRANSACTIONS.partnerTransactions, {
		params: {
			...data.filter,
			page: data.page,
			take: data.take,
			order: 'DESC',
			orderBy: 'transactionDate'
		}
	});
	return res?.data;
};

const PARTNERTRANSACTIONS = {
	getPartnerTransactions
};

export default PARTNERTRANSACTIONS;

import {Divider} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import i18n from '../../../utils/translate/i18';
import './MainTicket.less';

interface IMainTicket {
	oneUse: boolean;
	showTicketIco?: boolean;
	options: any;
}

const MainTicket = ({oneUse, showTicketIco, options, ...props}: IMainTicket) => {
	const price = typeof options.price === 'number' ? options.price : parseFloat(options.price);
	let {t} = useTranslation();
	let location = useLocation();
	return (
		<div className="main__ticket-container">
			{options.usedTrips && (
				<div className="ticket__not-used-trips">
					<div className="ticket__not-used-trips-header">
						{oneUse
							? `${t('pages.account.myTickets.mainTicket.notUsedTicket')}`
							: t('pages.account.myTickets.mainTicket.notUsedTicket')}
					</div>
					{!!options.tripsCount && (
						<div className="not-used-trips">
							{oneUse ? '0' : `${options.tripsCount - options.usedTrips}`}
						</div>
					)}
				</div>
			)}
			<div className="ticket__storage-type">
				<div className="ticket__storage-header">
					<p>{t('pages.tickets.oneUse.ticketCard.storage')}</p>
				</div>
				<div className="storage__type">
					<p>
						{/* @ts-ignore */}
						{options.holderType && options?.holderType[i18n.language]}
					</p>
				</div>
			</div>
			<div className="ticket__storage-type">
				<div className="ticket__storage-header">
					<p>{t('pages.tickets.oneUse.ticketCard.ticketType')}</p>
				</div>
				<div className="storage__type">
					<p>
						{/* @ts-ignore */}
						{/* {options.holderType && options?.holderType[i18n.language]} */}
						<p>{options.type[i18n.language]}</p>
					</p>
				</div>
			</div>
			<div className="ticket__trips-count">
				<div className="ticket__trips-header">
					<p>{t('pages.tickets.oneUse.ticketCard.tripCount')}</p>
				</div>
				<div className="trips__count">
					<p>{options.tripsCount + ' ' + t('pages.tickets.oneUse.ticketCard.trip')}</p>
				</div>
			</div>
			{options.carrierCode && (
				<div className="ticket__storage-key">
					<div className="ticket__storage-key-header">
						{oneUse ? '' : t('pages.account.myTickets.mainTicket.storageKey')}
					</div>
					<div className="storage-key">{oneUse ? '' : <p>{options.carrierCode}</p>}</div>
				</div>
			)}
			{options.createdAt && location.pathname === '/account/tickets' && (
				<div className="ticket__storage-key">
					<div className="ticket__storage-key-header">
						{t('pages.tickets.oneUse.ticketCard.purchaseDate')}
					</div>
					<div className="storage-key">
						{<p>{options.createdAt && moment(options.createdAt).format('DD-MM-YYYY')}</p>}
					</div>
				</div>
			)}
			{options.expiredAt && (
				<div className="ticket__storage-key">
					<div className="ticket__storage-key-header">
						{t('pages.account.ticketHoldersPage.ticketHolder.description.main.validDate.title')}
					</div>
					<div className="storage-key">
						{<p>{options.expiredAt && moment(options.expiredAt).format('DD-MM-YYYY')}</p>}
					</div>
				</div>
			)}
			<Divider style={{backgroundColor: '#009E83'}} />
			{options.price && (
				<div className="ticket__price">
					<div className="ticket__price-header">
						<p>{t('pages.tickets.oneUse.ticketCard.price')}</p>
					</div>
					<div className="price">
						<p>{isNaN(price) ? 'Invalid price' : `${price.toFixed(0)} ${t('AMD')}`}</p>
					</div>
				</div>
			)}
			{options.validThruDateNumber && options.holderTypeId === 1 && (
				<div className="ticket__valid">
					<div className="ticket__valid-till">
						<div className="ticket__valid-till__header ticket__price-header">
							<p>{t('pages.account.myTickets.mainTicket.validTime')}</p>
						</div>
						<div className="valid-till__main">
							<p>
								{options.validThruDateNumber}{' '}
								{t(`pages.account.myTickets.mainTicket.${options.validThruDateUnit}`)}
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MainTicket;

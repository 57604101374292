import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const fields: ModalFormFieldTypesFunc = (t, {cityId}) => [
	{
		name: 'cityId',
		label: t('pages.account.partner.routes.filter.city.title'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		hiddenOptions: options => {
			return options.filter(elem => elem.id == cityId).map(elem => elem.id);
		},
		disabled: true,
		selectFirstAsDefault: true,
		dictionaryApiPath: 'cities'
	},
	{
		name: 'number',
		label: t('pages.account.partner.routes.routeItem.busNum'),
		type: 'select',
		depFieldKey: ['cityId'],
		disabledOnDep: true,
		depValue: [val => val],
		fetchOnDepValue: true,
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: 'number',
			valueKey: (val: any) => {
				return `${val.id}_${val.number}` as string;
			}
		}
	},
	{
		name: 'typeId',
		label: t('pages.account.partner.routes.filter.type.title'),
		type: 'select',
		placeholder: t('pages.account.partner.routes.filter.type.title'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'route-types'
	},
	{
		name: 'allocationStartDate',
		label: t('pages.account.partner.routes.filter.startDateRange.title'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'allocationEndDate',
		label: t('pages.account.partner.routes.filter.endDateRange.title'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'statusId',
		label: t('pages.account.partner.transactions.transferstatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		},
		dictionaryApiPath: 'route-statuses'
	}
];
export default fields;

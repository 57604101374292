import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const fields: ModalFormFieldTypesFunc = (t, {cityId}) => [
	{
		name: 'cityId',
		label: t('pages.account.partner.routes.filter.city.title'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		hiddenOptions: options => {
			return options.filter(elem => elem.id == cityId).map(elem => elem.id);
		},
		disabled: true,
		selectFirstAsDefault: true,
		dictionaryApiPath: 'cities'
	},
	{
		name: 'routeNumber',
		isRequired: false,
		label: t('pages.account.historyMarches.byroute'),
		type: 'select',
		depFieldKey: ['cityId'],
		disabledOnDep: true,
		depValue: [val => val],
		fetchOnDepValue: true,
		colProps: {
			lg: 12,
			xs: 24
		},
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: 'number',
			valueKey: (val: any) => {
				return `${val.id}_${val.number}` as string;
			}
		}
	},
	{
		name: 'typeId',
		label: t('pages.account.partner.routes.filter.type.title'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'transport-types'
	},
	{
		name: 'categoryId',
		label: t('pages.account.partner.buses.filter.byManufacturer'),
		type: 'select',
		isRequired: false,
		selectProps: {
			options: [
				{
					label: 'Գազել',
					value: '2'
				},
				{
					label: 'Մերսեդես',
					value: '1'
				}
			]
		},
		colProps: {
			lg: 12,
			xs: 24
		}
	},

	{
		name: 'licensePlate',
		label: t('pages.account.partner.routes.popups.buses.numberPlate'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		}
	},
	{
		name: 'yellowLicensePlate',
		label: t('pages.account.partner.routes.popups.buses.yellowNumberPlate'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		}
	},
	{
		name: 'yellowLicensePlateReceiptDate',
		label: t('pages.account.partner.routes.popups.buses.yellowNumberPlateData'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'yellowLicensePlateDeadline',
		label: t('pages.account.partner.routes.popups.buses.yellowNumberPlateData1'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default fields;

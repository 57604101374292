import {useTranslation} from 'react-i18next';
import './styles.less';
import i18n from '../../../../../utils/translate/i18';
import moment from 'moment';
import BusNumber from '../../../../Schedule/BusNumber/BusNumber';
import {Divider} from 'antd';
import dayjs from 'dayjs';

const locales: any = {
	hy: 'hy-am',
	en: 'en-gb'
};

const TransactionData = ({options}: any) => {
	const {t} = useTranslation();

	const getTagColor = (statusId: number) => {
		switch (statusId) {
			case 1010:
				return '#009e83';
			case 1020:
				return '#FE5050';
			default:
				return 'default';
		}
	};

	if (!options) {
		return <div>{t('error.invalidTransactionData')}</div>;
	}
	return (
		<div className="transaction-data">
			<div className="transaction-data-container">
				<div className="transaction-data-content">
					<div className="transaction-data-row">
						<div className="transaction-data__item">
							<div className="transaction-data__item-row">
								<div
									className="transaction-data__item-info"
									style={{wordWrap: 'break-word', wordBreak: 'break-all'}}>
									{dayjs(options.transactionDate)
										.locale(locales[i18n.language] || 'en-gb')
										.format('DD-MM-YYYY HH:mm')}
								</div>
							</div>

							<Divider style={{background: '#009E83'}} />
							<div className="transaction-data__item-row transaction-data-modal-top">
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.account.partner.transactions.transferstatus')}
									</div>
									<div
										className="transaction-data__item-info"
										style={{color: getTagColor(options.statusId)}}>
										{options.status ? options.status[i18n.language] || '-' : '-'}
									</div>
								</div>
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.account.partner.transactions.transactionType')}
									</div>
									<div className="transaction-data__item-info">
										<span>{options.type ? options.type[i18n.language] || '-' : '-'}</span>
										{options.failureReason && <span> : </span>}
										<span>{options.failureReason || ''}</span>
									</div>
								</div>
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.tickets.oneUse.ticketCard.storage')}
									</div>
									<div className="transaction-data__item-info">
										{options.holderType ? options.holderType[i18n.language] || '-' : '-'}
									</div>
								</div>
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.account.partner.transactions.amount')}
									</div>
									<div className="transaction-data__item-info">
										{options.amount ? options.amount.slice(0, -3) : '-'}
									</div>
								</div>
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.account.partner.transactions.routes')}
									</div>
									<div className="transaction-data__item-info">
										{options.routeNumber !== null ? (
											<BusNumber number={options.routeNumber} />
										) : (
											'-'
										)}
									</div>
								</div>
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.account.partner.buses.titles')}
									</div>
									<div className="transaction-data__item-info">
										{options.transportYellowLicensePlate ?? '-'}
									</div>
								</div>
								<div className="transaction-data__item-main">
									<div className="transaction-data__item-title">
										{t('pages.account.partner.transactions.terminal')}
									</div>
									<div className="transaction-data__item-info">
										{options.validatorSerialNumber ?? '-'}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransactionData;

import API from '../api';
import axiosClient from '../utils/axiosClient';
import {ISignIn, ISignUp} from './types';

const signIn = async (data: ISignIn) => {
	const res = await axiosClient.post(API.AUTH.signIn, data);
	return res?.data;
};

const signInWithEmail = async (data: any) => {
	const res = await axiosClient.post(API.AUTH.signInWithEmail, data);
	return res?.data;
};

const signUp = async (data: ISignUp) => {
	const res = await axiosClient.post(API.AUTH.signUp, data);
	return res;
};

const signOut = async () => {
	const res = await axiosClient.post(API.AUTH.signOut);
	return res;
};

const resetPassword = async (data: any) => {
	const res = await axiosClient.post(API.AUTH.resetPassword, data);
	return res;
};

const setPassword = async (data: any) => {
	const res = await axiosClient.post(API.AUTH.setPassword, data);
	return res;
};

const resendOtp = async (data: any) => {
	const res = await axiosClient.post(API.AUTH.resendEmail, data);
	return res;
};

const AUTH = {
	signIn,
	signUp,
	signOut,
	resetPassword,
	resendOtp,
	signInWithEmail,
	setPassword
};

export default AUTH;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';
const initialState = {
	data: [],
	formErrors: {}
};

export const getforgotPassword: any = createAsyncThunk(
	'getforgotPassword',
	async (state: any, {rejectWithValue}) => {
		let data: any;
		try {
			data = await SERVICES.FORGOTPASSWORD.getforgotPassword(state);
		} catch (err) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

const forgotPassword = createSlice({
	name: 'forgotPassword',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getforgotPassword.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getforgotPassword.rejected, (state, action: any) => {
				state.formErrors = t(`messages.code.${action.payload.code}`);
			});
	}
});
export const {cleanFormErrors} = forgotPassword.actions;

export default forgotPassword.reducer;

import {useDispatch, useSelector} from 'react-redux';
import DataExport from '../../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import RouteItem from './RouteItem/RouteItem';
import './Routes.less';
import filteritems from './filter.form';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import {AppDispatch} from '../../../../../store';
import {useEffect, useState} from 'react';
import {getPartnerRoutes} from '../../../../../store/partner.routes.slice';
import {Empty, Spin} from 'antd';
import {routes} from './../../../../../router';
import dayjs from 'dayjs';
import i18n from '../../../../../utils/translate/i18';
import {t} from 'i18next';
import {useLocation} from 'react-router-dom';

const Routes = () => {
	const dispatch = useDispatch<AppDispatch>();
	const {pathname} = useLocation();
	const currentPageName = '/partner/routes';
	const partnerRoutes = useSelector(({partnerRoutes}) => partnerRoutes.data);
	const auth = useSelector((state: any) => state.auth.data);
	const [pageSize, setPageSize] = useState(4);
	const {currentItemsData, currentPage, goToPage} = usePagination(
		partnerRoutes?.items,
		partnerRoutes?.pageSize
	);
	const [info, setInfo] = useState<any>(null);
	useEffect(() => {
		let newValues: Record<string, any> = {};
		if (Object.keys(info ?? {})?.length) {
			newValues = {...info};
			if (info?.number) {
				newValues.number = info.number.split('_')[1];
			}
		}
		if (currentPageName === pathname) {
			dispatch(getPartnerRoutes({page: currentPage, filter: newValues, take: pageSize}));
		}
	}, [auth.id, currentPage, info, pathname, pageSize]);
	const columns = [
		{dataIndex: 'city', title: t('fields.city')},
		{dataIndex: 'number', title: t('pages.account.partner.routes.routeItem.busNum')},
		{dataIndex: 'type', title: t('pages.account.partner.routes.routeItem.busType')},
		{dataIndex: 'mode', title: t('pages.account.partner.routes.titles')},
		{
			dataIndex: 'allocationStartDate',
			title: t('pages.account.partner.routes.routeItem.routeAllocationDate')
		},
		{
			dataIndex: 'allocationEndDate',
			title: t('pages.account.partner.routes.routeItem.routeAllocationEndDate')
		},
		{
			dataIndex: 'allocationNumber',
			title: t('pages.account.partner.routes.routeItem.routeAllocationCode')
		},
		// {
		// 	dataIndex: 'workContract',
		// 	title: t('pages.account.partner.routes.routeItem.buttons.marchWay')
		// },
		{
			dataIndex: 'status',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title')
		}
	];

	const rows = currentItemsData?.map((transaction: any) => {
		return {
			// fullName: transaction[getKeyByLang('fullName')],
			city: transaction?.city[i18n.language],
			number: transaction?.number,
			type: transaction?.type[i18n.language],
			mode: transaction?.mode[i18n.language],
			allocationStartDate: dayjs(transaction?.allocationStartDate).format('DD-MM-YYYY'),
			allocationEndDate: dayjs(transaction?.allocationEndDate).format('DD-MM-YYYY'),
			allocationNumber: transaction?.allocationNumber,
			// allocationNumber: transaction?.allocationNumber,
			status: transaction?.status[i18n.language]
		};
	});
	console.log(auth.cityId);
	return (
		<div className="partner-routes">
			<div className="partner-routes__container">
				<div className="partner-routes__content">
					<div className="partner-routes__top">
						<DataExport exportData={{rows, columns}} />
						<DefaultWrapper
							filteritems={filteritems}
							defaultFilters={info}
							applyFilters={(data: any) => {
								setInfo(data);
							}}
							customFilters={{cityId: auth.cityId}}
						/>
					</div>
					{currentItemsData && currentItemsData.length > 0 ? (
						<>
							<div className="partner-routes__row">
								<div className="partner-routes">
									<div className="partner-routes-container">
										<div className="partner-routes-content">
											<div className="partner-routes-row" style={{gap: 0}}>
												{currentItemsData?.map((elem: any) => (
													<RouteItem key={elem.id} options={elem} />
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style={{marginTop:20}}>
								<PagePagination
									pageSizeOptions={[4, 10, 20, 50, 100]}
									pageSize={partnerRoutes?.pageSize}
									currentPage={partnerRoutes?.currentPage}
									totalItems={partnerRoutes?.totalCount}
									onPageChange={goToPage}
									onTakeChange={size => {
										setPageSize(size);
										goToPage(1); // Reset to the first page when page size changes
									}}
								/>
							</div>
						</>
					) : (
						<div style={{padding: '50px 0px'}}>
							<Empty />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Routes;

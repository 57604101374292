import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const providedServicesform: ModalFormFieldTypesFunc = t => [
	{
		name: 'statusId',
		label: t('pages.account.partner.transfersHistory.bystatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},

		dictionaryApiPath: 'transfer-statuses'
	},
	{
		name: 'periodStartDate',
		label: t('pages.account.partner.transfersHistory.filter.date'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		
		name: 'totalAmount',
		label: t('pages.account.partner.transfersHistory.filter.money'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'notes',
		label: t('pages.account.partner.transfersHistory.filter.notes'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'transferDate',
		label: t('pages.account.partner.transfersHistory.filter.transferDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default providedServicesform;

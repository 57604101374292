import API from '../api';
import axiosClient from '../utils/axiosClient';

const getHoldersNumberBalance = async (number: string) => {
	const res = await axiosClient.get(`${API.HOLDERSACTIONS.holdersNuberBalance}/${number}/balance`);
	return res?.data;
};

const topUpHoldersBalance = async (data: any) => {
	const res = await axiosClient.post(`${API.PAYMENTS.holderTopUp}`, data);
	return res?.data;
};

const HOLDERSNUBERBALANCE = {
	getHoldersNumberBalance,
	topUpHoldersBalance
};

export default HOLDERSNUBERBALANCE;
